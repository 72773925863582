import React from "react";
import { Container, Image, Button, Card, Form } from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import H1 from "../images/cntct.png"
import W1 from "../images/works01.jpg"

console.log(H1); // MV image
console.log(W1); // MV image

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <Image src={H1} fluid />
    <br></br><br></br>
    <Container>
    <Form method="post" name="contact" method="POST" data-netlify="true">
    <input type="hidden" name="form-name" value="contact" />
    <Form.Label>Inquiry Type / お問い合わせ種別</Form.Label>
    {['checkbox'].map((type) => (
    <div key={`default-${type}`} className="mb-3">
      <Form.Check 
        type={type}
        label="Web development / サイト制作について"
        name="Web development / サイト制作について"
        id={`default-${type}`}
      />
            <Form.Check 
        type={type}
        label="E-commerce / EC・Web販売について"
        name="E-commerce / EC・Web販売について"
        id={`default-${type}`}
      />
            <Form.Check 
        type={type}
        label="Others / その他"
        name="Others / その他"
        id={`default-${type}`}
      />
    </div>
  ))}
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Email address / メールアドレス</Form.Label>
    <Form.Control type="email" placeholder="Enter email" name="email"　required/>
    <Form.Text className="text-muted">
      We'll never share your email with anyone else.
    </Form.Text>
  </Form.Group>
  <Form.Label>Name / お名前</Form.Label>
  <Form.Group controlId="formBasicText">
  <Form.Control type="text" placeholder="Enter Your Name" name="name" required/>
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlTextarea1">
  <Form.Label>Details of the inquiry / お問い合わせ内容詳細</Form.Label>
  <Form.Control as="textarea" rows={3} placeholder="Text" name="detail" required/>
  </Form.Group>
  <Form.Group controlId="formBasicCheckbox">
  <Form.Check type="checkbox" label="I agree to the privacy policy / 個人情報取り扱いに同意します" name="pp_check" required/>
  <p><a href="/privacypolicy" target="_blank">
      Our Privacy Policy here</a>
  </p>
  </Form.Group>
  <Button variant="danger" type="submit">
    Submit / 送信
  </Button>
</Form>
</Container>
  </Layout>
);
export default ContactPage;
